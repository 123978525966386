<template>
  <div></div>
</template>

<script>

import store from '@state/store'

export default {
  name: 'logout',
  store,
  created() {
    this.$axios
        .post('/Login/Logout')
        .then(() => {
          this.$store.commit('logout')
          this.$router.push(
              '/login'
          )
        })
  }
}
</script>

<style scoped>

</style>